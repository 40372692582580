.containerDayOff {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-direction: column;
  max-width: 100%;
  max-height: 100%;
}

.slectTypeDayOff {
  width: 500px;
  height: 30px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.descriptionTextDayOff {
  height: 100px;
  margin-top: 5px;
  margin-bottom: 5px;
  resize: none;
}

.btnDayOff {
  height: 30px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.formStyleDayOff {
  display: flex;
  flex-direction: column;
}

.Selectable
  .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
  background-color: #D2C8ED !important;
  color: #4b3a7a;
}

.Selectable .DayPicker-Day {
  border-radius: 0 !important;
}

.Selectable .DayPicker-Day--start:not(.DayPicker-Day--outside) {
  background-color: #7E62CC !important;
  border-top-left-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}

.Selectable .DayPicker-Day--end:not(.DayPicker-Day--outside) {
  background-color: #7E62CC !important;
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
}