.containerLogin {
  display: flex;
  flex-direction: row;
  width: 100%;
  min-width: 300px;
  height: 100%;
  overflow: hidden;
}

.imgContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  width: 60%;
  object-fit: cover;
  max-width: 50%;
}

.loginImage {
  object-fit: cover;
  height: 100vh;
  width: 100%;
}
